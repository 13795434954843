@import url('https://fonts.googleapis.com/css2?family=Gothic+A1:wght@100;300;500;700&family=Roboto:ital,wght@0,300;0,400;0,900;1,300;1,500&display=swap');

body {
  margin: 0;
  font-family: 'nanumSquare-light', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',  sans-serif;
  /* font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',  sans-serif; */
  /* font-size: 1rem; */
  font-size: 16px;
  font-weight: 300;
  color: #333;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #efeff6;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

select.form-control,
textarea.form-control,
input[type='file'] {
  width: 100%;
}

input[type='password'] {
  font-family: Lato, Verdana, sans-serif;
  font-family: 'Gothic A1', sans-serif;
}

h1 {
  padding: 30px 0;
  text-align: center;
}
h2 {
  padding-top: 20px;
  padding-bottom: 20px;
}

h1,
h2,
h3,
h4 {
  font-weight: 500;
}
h5 {
  font-weight: 300;
}

h1 {
  font-size: 3.2rem;
}
h2 {
  font-size: 2.4rem;
}
h3 {
  font-size: 2rem;
}
h4 {
  font-size: 1.6rem;
}
h5 {
  font-size: 1.4rem;
}

.card-title {
  font-weight: 600;
}

@media screen and (max-width: 1080px) {
  h1 {
    font-size: 2.2rem;
  }
  h2 {
    font-size: 1.8rem;
  }
  h3 {
    font-size: 1.6rem;
  }
  h4 {
    font-size: 1.4rem;
  }
  h5 {
    font-size: 1.2rem;
  }

  body {
    font-size: 0.8rem;
  }
}

@font-face {
  font-family: 'nanumSquare-bold';
  /* src: url(./fonts/NanumSquareB.eot); */
  /* src: url(./fonts/NanumSquareB.eot?#iefix)format("embedded-opentype"); */
  /* src: url(./fonts/NanumSquareB.woff)format("woff"); */
  src: url(./fonts/NanumSquareB.ttf) format('truetype');
}
@font-face {
  font-family: 'nanumSquare-extra-bold';
  /* src: url(./fonts/NanumSquareEB.eot); */
  /* src: url(./fonts/NanumSquareEB.eot)format("embedded-opentype"); */
  /* src: url(./fonts/NanumSquareEB.woff)format("woff"); */
  src: url(./fonts/NanumSquareEB.ttf) format('truetype');
}
@font-face {
  font-family: 'nanumSquare-light';
  /* src: url(./fonts/NanumSquareL.eot); */
  /* src: url(./fonts/NanumSquareL.eot?#iefix)format("embedded-opentype"); */
  /* src: url(./fonts/NanumSquareL.woff)format("woff"); */
  src: url(./fonts/NanumSquareL.ttf) format('truetype');
}
