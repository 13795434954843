/**
 * define App.js style for 
 *  Navigation tab,
 *  Footer
 * 
 */

.navbar-brand {
  font-weight: bold;
}

.nav-bg-blue {
  background-color: deepskyblue;
  /* background-color: rgba(200,180,0, 0.9) !important;  */
}

.navbar-brand a {
  color: white;
  text-decoration: none;
}
.navbar-brand a:hover {
  color: aliceblue;
  text-decoration: none;
}

/*
.navbar-nav a, .navbar-text {
  padding: 20px !important;
  color: white !important;
}

.navbar-nav a:hover {
  color: rgb(241, 184, 163) !important;  
}

.dropdown-menu a {  
  padding: 5px 10px !important;
  color: steelblue !important
} */

.navbar-nav {
  padding: 5px 15px;
}

.nav-link,
.navbar-text {
  margin: 0 3px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.6) !important;
  /* background-color: #555; */
}

.nav-link:hover,
.nav-link.active {
  color: white !important;
}

.dropdown-item {
  color: gray !important;
}

.dropdown-item:hover,
.dropdown-item.active {
  color: skyblue !important;
  background-color: white !important;
}

@media screen and (min-width: 1080px) {
  /* .navbar {
    padding: .5rem 10rem !important;
  } */
}
