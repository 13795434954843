.onl-log {  
  /* margin: 5px; */
  padding: 10px;
  background-color: #fff;
  /* border-left: 3px solid gray ; */
}

.onl-log .onl-log-title {  
  margin: 10px 0;
  font-weight: 600;
}

.onl-log p {
  font-family: Roboto;
  font-size: 14px;
  margin-bottom: 2px;
  color: #555;
}