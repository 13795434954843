@import url(https://fonts.googleapis.com/css2?family=Gothic+A1:wght@100;300;500;700&family=Roboto:ital,wght@0,300;0,400;0,900;1,300;1,500&display=swap);
.NotFound {
  padding-top: 100px;
  text-align: center;
}
.react-calendar {
  /* width: 350px; */
  max-width: 100%;
  background: white;
  border: 1px solid #a0a096;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
  width: 300px;         /* 수정됨 */
  font-size: 12px;      /* 추가됨 */
}
.react-calendar--doubleView {
  width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  box-sizing: border-box;
}
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  display: flex;
  height: 44px;
  margin-bottom: 1em;
}
.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}
.react-calendar__navigation button:disabled {
  background-color: #f0f0f0;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  font-weight: bold;
}
.react-calendar__month-view__days__day--weekend {
  color: #d10000;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}
.react-calendar__tile {
  max-width: 100%;
  padding: 10px 6.6667px;
  background: none;
  text-align: center;
  line-height: 16px;
}
.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__tile--now {
  background: #ffff76;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #ffffa9;
}
.react-calendar__tile--hasActive {
  background: #76baff;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}
.react-calendar__tile--active {
  background: #006edc;
  color: white;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #1087ff;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}

.react-calendar {
  /* width: 350px; */
  max-width: 100%;
  background: white;
  border: 1px solid #a0a096;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
  width: 300px;         /* 수정됨 */
  font-size: 12px;      /* 추가됨 */
}
.react-calendar--doubleView {
  width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  box-sizing: border-box;
}
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  display: flex;
  height: 44px;
  margin-bottom: 1em;
}
.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}
.react-calendar__navigation button:disabled {
  background-color: #f0f0f0;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  font-weight: bold;
}
.react-calendar__month-view__days__day--weekend {
  color: #d10000;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}
.react-calendar__tile {
  max-width: 100%;
  padding: 10px 6.6667px;
  background: none;
  text-align: center;
  line-height: 16px;
}
.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__tile--now {
  background: #ffff76;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #ffffa9;
}
.react-calendar__tile--hasActive {
  background: #76baff;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}
.react-calendar__tile--active {
  background: #006edc;
  color: white;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #1087ff;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}

.onl-post img { width: 100% }
.onl-post .img-sm { width: 50% }
.onl-post .img-mid { width: 70% }
.onl-post .img-lg { width: 90% }

.onl-post strong { font-weight: bold; }

.onl-post h2 { 
  font-size: 2rem;
  padding: 2rem 0 1rem;
}
.onl-post h3 {
  font-size: 1.6rem;
  padding: 1.6rem 0 1rem;
}
.onl-post h4 {
  font-size: 1.4rem;
  padding: 1.4rem 0 1rem;
}
.onl-post h5 {
  font-size: 1.2rem;
  padding: 1.2rem 0 1rem;
}
.onl-post h6 {
  font-size: 1.1rem;
  padding: 1.1rem 0 1rem;
}

/** **************************************************************************************/
/* <pre><code>  */
/** **************************************************************************************/

.onl-post pre {
  background-color: #efefef;
  /* color: #202020 !important; */  
  border: none;  
  overflow-x: auto;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  padding: 15px
  /* font-size: 8px;
  line-height: 1.2 */
}

.onl-post code { 
  font-size: 1rem; 
  /* line-height: 1.2  */
}

.onl-post pre > code {
  font-size: 0.8rem;
}

/* md file이 소스인 경우에 필요함. */
.onl-post li > p {
  margin: 10px 0px 5px;
}

/** **************************************************************************************/
/* Header Numbering  */
/** **************************************************************************************/
body {counter-reset : h2; }
.onl-post h2 {counter-reset : h3;}
.onl-post h3 {counter-reset : h4;}
.onl-post h4 {counter-reset : h5; }
.onl-post h5 {counter-reset : h6; }
.onl-post h2:before {
  content : counter(h2,decimal) ". ";
  counter-increment : h2;
}
.onl-post h3:before {
  content : counter(h2,decimal) "." counter(h3,decimal) ". ";
  counter-increment : h3;
}
.onl-post h4:before {
  content : counter(h2,decimal) "." counter(h3,decimal) "." counter(h4,decimal) ". ";
  counter-increment : h4;
}
.onl-post h5:before {
  content : counter(h2,decimal) "." counter(h3,decimal) "." counter(h4,decimal) "." counter(h5,decimal) ". ";
  counter-increment : h5;
}
.onl-post h6:before {
  content : counter(h2,decimal) "." counter(h3,decimal) "." counter(h4,decimal) "." counter(h5,decimal) "." counter(h6,decimal) ". ";
  counter-increment : h6;
}
.onl-post h2.nocount:before, h3.nocount:before, h4.nocount:before, h5.nocount:before, h6.nocount:before {
  content : "";
  counter-increment : none;
}

.onl-log {  
  /* margin: 5px; */
  padding: 10px;
  background-color: #fff;
  /* border-left: 3px solid gray ; */
}

.onl-log .onl-log-title {  
  margin: 10px 0;
  font-weight: 600;
}

.onl-log p {
  font-family: Roboto;
  font-size: 14px;
  margin-bottom: 2px;
  color: #555;
}
/**
 * define App.js style for 
 *  Navigation tab,
 *  Footer
 * 
 */

.navbar-brand {
  font-weight: bold;
}

.nav-bg-app {
  background-color: deepskyblue;
  /* background-color: rgba(200,180,0, 0.9) !important;  */
}

.nav-bg-admin {
  background-color: #445;
}

.navbar-brand a {
  color: white;
  text-decoration: none;
}
.navbar-brand a:hover {
  color: aliceblue;
  text-decoration: none;
}

/*
.navbar-nav a, .navbar-text {
  padding: 20px !important;
  color: white !important;
}

.navbar-nav a:hover {
  color: rgb(241, 184, 163) !important;  
}

.dropdown-menu a {  
  padding: 5px 10px !important;
  color: steelblue !important
} */

.navbar-nav {
  padding: 5px 15px;
}

.nav-link,
.navbar-text {
  margin: 0 3px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.6) !important;
  /* background-color: #555; */
}

.nav-link:hover,
.nav-link.active {
  color: white !important;
}

.dropdown-item {
  color: #eee !important;
}

.dropdown-item:hover,
.dropdown-item.active {
  color: skyblue !important;
  background-color: white !important;
}

.dropdown-menu {  
  background-color: #888 !important;
}

.dropdown-menu a:hover {
  color: #eee !important;
}

.nav-tabs a {
  color: skyblue !important;
  text-decoration: none;
}

.nav-tabs a:hover {
  color: steelblue !important;
  text-decoration: none;
}

.nav-tabs a.active {
  color: steelblue !important;
  text-decoration: none;
}

@media screen and (min-width: 1080px) {
  /* .navbar {
    padding: .5rem 10rem !important;
  } */
}

/**
 * define App.js style for 
 *  Navigation tab,
 *  Footer
 * 
 */

.navbar-brand {
  font-weight: bold;
}

.nav-bg-blue {
  background-color: deepskyblue;
  /* background-color: rgba(200,180,0, 0.9) !important;  */
}

.navbar-brand a {
  color: white;
  text-decoration: none;
}
.navbar-brand a:hover {
  color: aliceblue;
  text-decoration: none;
}

/*
.navbar-nav a, .navbar-text {
  padding: 20px !important;
  color: white !important;
}

.navbar-nav a:hover {
  color: rgb(241, 184, 163) !important;  
}

.dropdown-menu a {  
  padding: 5px 10px !important;
  color: steelblue !important
} */

.navbar-nav {
  padding: 5px 15px;
}

.nav-link,
.navbar-text {
  margin: 0 3px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.6) !important;
  /* background-color: #555; */
}

.nav-link:hover,
.nav-link.active {
  color: white !important;
}

.dropdown-item {
  color: gray !important;
}

.dropdown-item:hover,
.dropdown-item.active {
  color: skyblue !important;
  background-color: white !important;
}

@media screen and (min-width: 1080px) {
  /* .navbar {
    padding: .5rem 10rem !important;
  } */
}

body {
  margin: 0;
  font-family: 'nanumSquare-light', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',  sans-serif;
  /* font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',  sans-serif; */
  /* font-size: 1rem; */
  font-size: 16px;
  font-weight: 300;
  color: #333;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #efeff6;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

select.form-control,
textarea.form-control,
input[type='file'] {
  width: 100%;
}

input[type='password'] {
  font-family: Lato, Verdana, sans-serif;
  font-family: 'Gothic A1', sans-serif;
}

h1 {
  padding: 30px 0;
  text-align: center;
}
h2 {
  padding-top: 20px;
  padding-bottom: 20px;
}

h1,
h2,
h3,
h4 {
  font-weight: 500;
}
h5 {
  font-weight: 300;
}

h1 {
  font-size: 3.2rem;
}
h2 {
  font-size: 2.4rem;
}
h3 {
  font-size: 2rem;
}
h4 {
  font-size: 1.6rem;
}
h5 {
  font-size: 1.4rem;
}

.card-title {
  font-weight: 600;
}

@media screen and (max-width: 1080px) {
  h1 {
    font-size: 2.2rem;
  }
  h2 {
    font-size: 1.8rem;
  }
  h3 {
    font-size: 1.6rem;
  }
  h4 {
    font-size: 1.4rem;
  }
  h5 {
    font-size: 1.2rem;
  }

  body {
    font-size: 0.8rem;
  }
}

@font-face {
  font-family: 'nanumSquare-bold';
  /* src: url(./fonts/NanumSquareB.eot); */
  /* src: url(./fonts/NanumSquareB.eot?#iefix)format("embedded-opentype"); */
  /* src: url(./fonts/NanumSquareB.woff)format("woff"); */
  src: url(/static/media/NanumSquareB.e850eb66.ttf) format('truetype');
}
@font-face {
  font-family: 'nanumSquare-extra-bold';
  /* src: url(./fonts/NanumSquareEB.eot); */
  /* src: url(./fonts/NanumSquareEB.eot)format("embedded-opentype"); */
  /* src: url(./fonts/NanumSquareEB.woff)format("woff"); */
  src: url(/static/media/NanumSquareEB.9ee5085e.ttf) format('truetype');
}
@font-face {
  font-family: 'nanumSquare-light';
  /* src: url(./fonts/NanumSquareL.eot); */
  /* src: url(./fonts/NanumSquareL.eot?#iefix)format("embedded-opentype"); */
  /* src: url(./fonts/NanumSquareL.woff)format("woff"); */
  src: url(/static/media/NanumSquareL.f2a56a3b.ttf) format('truetype');
}

